
import {Component, Vue} from 'vue-property-decorator';
import {
  deliveryShopLabels,
  deliveryShops,
  riderInfos,
} from '../../../../../resources';
import {Form} from 'element-ui';
import Map from '../Map/index.vue';
import {ObjectId} from 'bson';
import {ShopLabel} from '@/externals/MaxCI-ShopLabel-v1';
import {GeometrySchema} from '@/externals/MaxCI-Shop-v1';
import {RiderInfo} from '@/externals/MaxCI-RiderInfo-v1';
import {AxiosError} from 'axios';

interface ShopValue extends ShopLabel {
  values: Array<{
    name: string;
    checked: boolean;
  }>;
}
interface RiderInfoId extends RiderInfo {
  id: string;
}
@Component({
  name: 'storeAdd',
  components: {
    Map,
  },
})
export default class extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private validName = (rule: any, value: string, callback: any) => {
    if (value.length > 32 || value.length < 2) {
      callback(new Error('名称必须长度必须小于32个字符且大于2个字符'));
    } else {
      callback();
    }
  };
  private validprice = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (!/(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(value)) {
      callback(new Error('价格格式有误'));
    } else {
      callback();
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private validPhone = (rule: any, value: string, callback: any) => {
    if (!/(^1[23456789]\d{9}$)/.test(value)) {
      callback(new Error('手机号格式不正确'));
    } else {
      callback();
    }
  };
  private dialogLabel = false; //选择标签弹窗
  private activeLabel = [0];
  private labelData: Array<ShopValue> = []; //标签数据
  private submitFlag = false; //提交开关
  private formData = {
    name: '',
    address: '',
    contactName: '',
    phone: '',
    geometry: {
      type: 'Point',
      coordinates: [0, 0] as [number, number],
    } as GeometrySchema,
    description: '',
    sorter: '',
    baseFee: 0,
    toHomeFee: 0,
    labels: [] as Array<string>,
    applicationId: ObjectId.createFromHexString(
      this.$route.params.applicationId,
    ) as ObjectId,
  };
  private rules = {
    name: [
      {required: true, message: '请输入门店名称', trigger: 'blur'},
      {validator: this.validName, trigger: 'blur'},
    ],
    address: [{required: true, message: '请输入门店地址', trigger: 'blur'}],
    phone: [
      {required: true, message: '请输入联系电话', trigger: 'blur'},
      {validator: this.validPhone, trigger: 'blur'},
    ],
    contactName: [{required: true, message: '请输入联系人', trigger: 'blur'}],
    baseFee: [
      {required: true, message: '请输入基础费用', trigger: 'blur'},
      {validator: this.validprice, trigger: 'blur'},
    ],
    toHomeFee: [
      {required: true, message: '请输入送货上门费用', trigger: 'blur'},
      {validator: this.validprice, trigger: 'blur'},
    ],
  };
  private sorterData: Array<RiderInfoId> = [];

  async created() {
    try {
      this.sorterData = (
        await riderInfos.find(stage =>
          stage.$match(match =>
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.formData.applicationId),
            )(
              f => f('spec')('type'),
              e => e.$eq('分拣员'),
            ),
          ),
        )
      ).map(v => {
        return {
          ...v,
          id: v.spec.userId.toHexString(),
        };
      });
      //查询标签数据
      const list = (await deliveryShopLabels.find(stage =>
        stage.$match(match =>
          match(
            f => f('spec')('applicationId'),
            e => e.$eq(this.formData.applicationId),
          ),
        ),
      )) as Array<ShopValue>;
      list.forEach(item => {
        item.values = [];
        if (item.values) {
          item.spec.values.forEach(valueItem => {
            item.values.push({
              name: valueItem,
              checked: false,
            });
          });
        }
      });
      this.labelData = list;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //选择标签保存
  private labelSumbit() {
    const labels = [] as Array<string>;
    this.labelData.forEach(item => {
      item.values.forEach(valueItem => {
        if (valueItem.checked) {
          labels.push(item.spec.name + ':' + valueItem.name);
        }
      });
    });
    this.formData.labels = labels;
    this.dialogLabel = false;
  }
  //处理标签数据
  private getLabel(label: string, type: string) {
    let value = '';
    if (type === 'name') {
      value = label.split(':')[0];
    } else {
      value = label.split(':')[1];
    }
    return value;
  }
  //选择标签
  private changeLabel(index: number, indexValue: number) {
    if (!this.labelData[index].spec.multiSelect) {
      this.labelData[index].values.forEach((item, labelIndex) => {
        if (labelIndex !== indexValue) {
          item.checked = false;
        }
      });
    }
    this.labelData[index].values[indexValue].checked =
      !this.labelData[index].values[indexValue].checked;
  }
  //获取地图数据
  private getPosi(arr: [number, number]) {
    this.formData.geometry.coordinates = arr;
  }
  //新增
  private async submitForm() {
    (this.$refs.formData as Form).validate(async valid => {
      if (valid) {
        if (this.formData.geometry.coordinates[0] === 0) {
          this.$message.error('请选择门店位置');
          return false;
        }
        try {
          this.submitFlag = true;
          const deliveryShop = await deliveryShops.create([
            {
              spec: {
                name: this.formData.name,
                address: this.formData.address,
                phone: this.formData.phone,
                contactName: this.formData.contactName,
                shopFee: {
                  baseFee: Math.round(Number(this.formData.baseFee) * 100),
                  toHomeFee: Math.round(Number(this.formData.toHomeFee) * 100),
                },
                ...(this.formData.sorter
                  ? {
                      sorterId: ObjectId.createFromHexString(
                        this.formData.sorter,
                      ),
                    }
                  : {}),
                description: this.formData.description,
                labels: this.formData.labels,
                applicationId: this.formData.applicationId,
                geometry: this.formData.geometry,
              },
            },
          ]);
          if (deliveryShop.length > 0) {
            this.$message.success('保存成功');
            this.backBtn();
          } else {
            this.$message.error('保存失败');
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (e.isAxiosError) {
            const axiosError = e as AxiosError;
            if (
              axiosError.response?.status === 500 &&
              (axiosError.response.data.name === 'MongoError' ||
                axiosError.response.data.name === 'Error') &&
              axiosError.response.data.message.indexOf('E11000') > -1
            ) {
              this.$message.error('名称不能重复');
            }
          }
        } finally {
          this.submitFlag = false;
        }
      } else {
        return false;
      }
    });
  }
  //重置
  private resetForm() {
    this.formData.geometry.coordinates = [0, 0];
    (this.$refs.formData as Form).resetFields();
  }
  //返回
  private backBtn() {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'list',
      },
    });
  }
}
