
import {Component, Vue} from 'vue-property-decorator';
import deliveryPointList from './list.vue';
import deliveryPointAdd from './add.vue';
import deliveryPointEdit from './edit.vue';
@Component({
  name: 'deliveryPointIndex',
  components: {
    deliveryPointList,
    deliveryPointAdd,
    deliveryPointEdit,
  },
})
export default class extends Vue {
  private type = '';
  created() {
    this.type = this.$route.query.type
      ? (this.$route.query.type as string)
      : 'list';
  }
}
