
import {Component, Vue, Watch} from 'vue-property-decorator';
import {
  deliveryOrderGroupLabels,
  deliveryOrderGroups,
} from '../../../../resources';
import {ObjectId} from 'bson';
import {Form} from 'element-ui';
import {DeliveryOrderGroup} from '@/externals/MaxCI-DeliveryOrderGroup-v1';
import vueQr from 'vue-qr';
import html2canvas from 'html2canvas';

interface DeliveryGroupOld extends DeliveryOrderGroup {
  oldName: string;
}
interface PointList {
  oldPointName: string;
  pointName: string;
  table: Array<DeliveryGroupOld>;
  show: boolean;
  createName: string;
  createRemark: string;
}
@Component({
  name: 'pointSetting',
  components: {
    vueQr,
  },
})
export default class extends Vue {
  private codeUrl = '';
  private dialogReview = false;
  private operateIndex: Array<number> = [];
  private selectSortName = ''; //选择的点位
  private submitFlag = false; //提交开关
  private groupName = '';
  private formData = {name: ''}; //添加点位
  private rules = {
    name: [{required: true, message: '请输入点位名称', trigger: 'blur'}],
  };
  private titleSort = '';
  private dialogSort = false; // 点位弹窗
  private operateId: ObjectId | null = null;
  private operateName = '';
  private dialogSortDel = false; //删除点位弹窗
  private dialogGoodsDel = false; //删除分组弹窗
  private listLoading = true;
  private applicationId = this.$route.params.applicationId;
  private sortData: Array<PointList> = []; //列表数据
  private oldSortData: Array<string> = []; //修改前点位数据

  async created() {
    this.updatePoint();
  }
  @Watch('dialogSort')
  private changeDialogSort() {
    this.formData.name = '';
  }
  //平台配置信息
  get platformConfig() {
    return this.$store.state.pConfig;
  }
  //列表查询
  private async updatePoint() {
    try {
      const deliveryOrderGroupLabel = await deliveryOrderGroupLabels.find(
        stage =>
          stage.$match(match =>
            match(
              f => f('spec')('name'),
              e => e.$eq('点位'),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(ObjectId.createFromHexString(this.applicationId)),
            ),
          ),
      );
      const list = [] as Array<PointList>;
      if (deliveryOrderGroupLabel.length > 0) {
        deliveryOrderGroupLabel[0].spec.values.forEach(item => {
          list.push({
            oldPointName: item,
            pointName: item,
            table: [],
            show: true,
            createName: '',
            createRemark: '',
          });
        });
        list.forEach(item => {
          deliveryOrderGroups
            .find(stage =>
              stage.$match(match =>
                match(
                  f => f('spec')('labels'),
                  e => e.$in(['点位:' + item.pointName]),
                )(
                  f => f('spec')('applicationId'),
                  e => e.$eq(ObjectId.createFromHexString(this.applicationId)),
                ),
              ),
            )
            .then(res => {
              item.table = res.map(v => {
                return {
                  ...v,
                  oldName: v.spec.name,
                };
              });
            });
        });
      }
      this.sortData = list;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //保存二维码
  private async saveImg() {
    const canvasId = this.$refs.html2canvas as HTMLElement;
    const canvas = await html2canvas(canvasId, {
      useCORS: true,
      scrollY: 0,
    });
    const url = canvas.toDataURL('image/jpeg');
    const a = document.createElement('a'); // 生成一个a元素
    const event = new MouseEvent('click'); // 创建一个单击事件
    a.download = this.groupName; // 设置图片名称
    a.href = url; // 将生成的URL设置为a.href属性
    a.dispatchEvent(event); // 触发a的单击事件
  }
  //生成二维码
  private codePopUps(id: ObjectId, name: string) {
    this.groupName = name;
    this.dialogReview = true;
    this.codeUrl = `https://qr.maxci.cn/g/${id.toHexString()}`;
  }
  //添加分组保存
  private async submitGroup(index: number) {
    if (this.sortData[index].createName) {
      const deliveryOrderGroup = await deliveryOrderGroups.create([
        {
          spec: {
            name: this.sortData[index].createName,
            remark: this.sortData[index].createRemark,
            applicationId: ObjectId.createFromHexString(
              this.$route.params.applicationId,
            ),
            labels: ['点位:' + this.sortData[index].pointName],
          },
        },
      ]);
      if (deliveryOrderGroup.length > 0) {
        this.sortData[index].createName = '';
        this.sortData[index].createRemark = '';
        const list = (await deliveryOrderGroups.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$eq(deliveryOrderGroup[0]._id),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(ObjectId.createFromHexString(this.applicationId)),
            ),
          ),
        )) as Array<DeliveryOrderGroup>;
        if (list.length > 0) {
          this.$set(
            this.sortData[index].table,
            this.sortData[index].table.length,
            list[0],
          );
        }
      }
    }
  }
  //修改保存
  private changeGroupName(index: number, groupIndex: number) {
    if (this.sortData[index].table[groupIndex].spec.name) {
      deliveryOrderGroups
        .update(
          filter =>
            filter(
              f => f('_id'),
              e => e.$eq(this.sortData[index].table[groupIndex]._id),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(ObjectId.createFromHexString(this.applicationId)),
            ),
          update =>
            update.$set(s =>
              s(
                f => f('spec')('name'),
                this.sortData[index].table[groupIndex].spec.name,
              )(
                f => f('spec')('remark'),
                this.sortData[index].table[groupIndex].spec.remark ?? '',
              ),
            ),
        )
        .then(res => {
          if (res.length > 0) {
            this.sortData[index].table[groupIndex].oldName =
              this.sortData[index].table[groupIndex].spec.name;
          }
        });
    } else {
      this.sortData[index].table[groupIndex].spec.name =
        this.sortData[index].table[groupIndex].oldName;
    }
  }
  //添加点位保存
  private async sortSubmit() {
    (this.$refs.formData as Form).validate(async valid => {
      if (valid) {
        try {
          this.submitFlag = true;
          const deliveryOrderGroupLabel = await deliveryOrderGroupLabels.update(
            filter =>
              filter(
                f => f('spec')('name'),
                e => e.$eq('点位'),
              )(
                f => f('spec')('applicationId'),
                e => e.$eq(ObjectId.createFromHexString(this.applicationId)),
              ),
            update =>
              update
                .$addToSet(add =>
                  add(f => f('spec')('values'), this.formData.name),
                )
                .$set(s => s(f => f('spec')('multiSelect'), false)),
            {
              upsert: true,
            },
          );
          if (deliveryOrderGroupLabel.length > 0) {
            this.$message.success('保存成功');
            this.dialogSort = false;
            this.updatePoint();
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.$message.error('网络异常，请稍后重试');
        } finally {
          this.submitFlag = false;
        }
      } else {
        return false;
      }
    });
  }
  //添加点位弹窗
  private addsort() {
    this.dialogSort = true;
    this.titleSort = '添加点位';
  }
  private getWidth(str: string) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    ctx.font = 'bold 16px 微软雅黑';
    const text = ctx.measureText(str);
    return text.width ? text.width : 5;
  }
  //修改点位名称
  private sortFocus(name: string) {
    document.getElementById(name)?.focus();
  }
  //修改点位
  private async changeSortName(index: number) {
    if (this.sortData[index].pointName) {
      await deliveryOrderGroupLabels
        .update(
          filter =>
            filter(
              f => f('spec')('name'),
              e => e.$eq('点位'),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(ObjectId.createFromHexString(this.applicationId)),
            ),
          update =>
            update.$set(s =>
              s(
                f => f('spec')('values')(index),
                this.sortData[index].pointName,
              )(
                f => f('spec')('values')(index),
                this.sortData[index].pointName,
              ),
            ),
        )
        .then(res => {
          if (res.length > 0) {
            this.sortData[index].oldPointName = this.sortData[index].pointName;
          }
        });
      await deliveryOrderGroups.update(
        filter =>
          filter(
            f => f('spec')('labels'),
            e => e.$in(['点位:' + this.oldSortData[index]]),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(ObjectId.createFromHexString(this.applicationId)),
          ),
        update =>
          update.$set(s =>
            s(
              f => f('spec')('labels')('$'),
              '点位:' + this.sortData[index].pointName,
            ),
          ),
      );
    } else {
      this.sortData[index].pointName = this.sortData[index].oldPointName;
    }
  }
  //删除分组提示
  private groupDelPopUps(index: number, groupIndex: number) {
    this.operateId = this.sortData[index].table[groupIndex]._id;
    this.operateIndex = [index, groupIndex];
    this.dialogGoodsDel = true;
  }
  //删除分组保存
  private delGoodsSumbit() {
    deliveryOrderGroups
      .delete(filter =>
        filter(
          f => f('_id'),
          e => e.$eq(this.operateId as ObjectId),
        )(
          f => f('spec')('applicationId'),
          e => e.$eq(ObjectId.createFromHexString(this.applicationId)),
        ),
      )
      .then(res => {
        if (res.length > 0) {
          this.$message.success('删除成功');
          this.dialogGoodsDel = false;
          this.sortData[this.operateIndex[0]].table.splice(
            this.operateIndex[1],
            1,
          );
        }
      });
  }
  //删除点位提示
  private delSortPopUps(name: string) {
    this.operateName = name;
    this.dialogSortDel = true;
  }
  //删除点位保存
  private async delSortSumbit() {
    try {
      const deliveryOrderGroupLabel = await deliveryOrderGroupLabels.update(
        filter =>
          filter(
            f => f('spec')('name'),
            e => e.$eq('点位'),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(ObjectId.createFromHexString(this.applicationId)),
          ),
        update =>
          update.$pull(p =>
            p(f => f('spec')('values'), 'value', this.operateName),
          ),
      );
      if (deliveryOrderGroupLabel.length > 0) {
        this.$message.success('删除成功');
        this.dialogSortDel = false;
        this.updatePoint();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //展开收起
  private showBtn(index: number) {
    this.sortData[index].show = !this.sortData[index].show;
  }
}
