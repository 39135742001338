
import {Component, Vue} from 'vue-property-decorator';
import multiSelect from '@/components/multiSelect/index.vue';
import Pagination from '@/components/Pagination/index.vue';
import {riderInfos, roleDeleteRequests} from '../../../../resources';
import {ObjectId} from 'bson';
import {RiderInfo} from '@/externals/MaxCI-RiderInfo-v1';
@Component({
  name: 'deliveryList',
  components: {
    multiSelect,
    Pagination,
  },
})
export default class extends Vue {
  private reason = '';
  private saveLoading = false;
  private dialogDel = false;
  private operateId: null | ObjectId = null;
  private operateData: null | RiderInfo = null;
  private dialogReview = false; // 操作弹窗
  private title = '';
  private total = 0;
  private list: Array<RiderInfo> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private searchData = {name: '', phone: '', working: '' as string | boolean};
  async created() {
    this.updateList();
  }
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  //删除提示
  private delPopUps(row: RiderInfo) {
    this.operateId = row._id;
    this.operateData = row;
    this.dialogDel = true;
  }
  //删除
  private async delSumbit() {
    try {
      this.saveLoading = true;
      const riderInfo = await riderInfos.delete(filter =>
        filter(
          f => f('_id'),
          e => e.$eq(this.operateId as ObjectId),
        )(
          f => f('spec')('applicationId'),
          e =>
            e.$eq(
              ObjectId.createFromHexString(this.$route.params.applicationId),
            ),
        ),
      );
      if (riderInfo) {
        const roleDeleteRequest = await roleDeleteRequests.create(
          [
            {
              spec: {
                type: '配送员骑手',
                userId: (this.operateData as RiderInfo).spec.userId,
                applicationId: ObjectId.createFromHexString(
                  this.$route.params.applicationId,
                ),
              },
            },
            {
              spec: {
                type: '配送员应用',
                userId: (this.operateData as RiderInfo).spec.userId,
                applicationId: ObjectId.createFromHexString(
                  this.$route.params.applicationId,
                ),
              },
            },
          ],
          {
            watch: {
              filter: filter =>
                filter(
                  f => f('operationType'),
                  e => e.$eq('update'),
                )(
                  f => f('fullDocument')('status')('phase'),
                  e => e.$exists(true),
                ),
            },
            fullResource: true,
          },
        );
        if (roleDeleteRequest) {
          this.dialogDel = false;
          this.operateId = null;
          this.$message.success('已删除');
          this.updateList();
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.saveLoading = false;
    }
  }
  //查看详情
  private toDetail(id: ObjectId) {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'detail',
        id: id.toHexString(),
      },
    });
  }
  //搜索数据
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  private async updateList() {
    this.listLoading = true;
    try {
      const list = await riderInfos.find(stage =>
        stage
          .$match(match => {
            if (this.searchData.name) {
              match(
                f => f('spec')('name'),
                e => e.$regex(new RegExp(this.searchData.name)),
              );
            }
            if (this.searchData.phone) {
              match(
                f => f('spec')('phone'),
                e => e.$regex(new RegExp(this.searchData.phone)),
              );
            }
            if (this.searchData.working !== '') {
              match(
                f => f('spec')('working'),
                e => e.$eq(this.searchData.working as boolean),
              );
            }
            match(
              f => f('spec')('type'),
              e => e.$eq('骑手'),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            );
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('creationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
}
