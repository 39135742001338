
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import Amap from 'vue-amap';
Vue.use(Amap);
Amap.initAMapApiLoader({
  key: 'd6dbe529c095ba542d60b26bf1059e13',
  plugin: [
    'AMap.Autocomplete',
    'AMap.PlaceSearch',
    'AMap.Scale',
    'AMap.OverView',
    'AMap.ToolBar',
    'AMap.MapType',
    'AMap.PolyEditor',
    'AMap.CircleEditor',
    'AMap.Geocoder',
    'AMap.Geolocation',
  ],
  v: '1.4.4',
});

@Component({
  name: 'Map',
})
export default class extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop() coordinates: any;
  private index = 0;
  private zoom = 14;
  private center = [114.306412, 30.60344]; //地图中心点坐标
  private mapStyle = 'amap://styles/8b6be8ec497009e17a708205348b899a'; //设置地图样式
  private markers = [[114.306412, 30.60344]];
  private searchOption = {
    citylimit: false,
  };
  private lng = 0;
  private lat = 0;
  private address = '';
  private events = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    click: (e: any) => {
      this.$emit('getLnglat', [e.lnglat.lng, e.lnglat.lat]);
      this.changePosition(e.lnglat.lng, e.lnglat.lat);
    },
  };

  @Watch('coordinates')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCoordinatesChange(val: any) {
    if (val.length !== 0 && this.index === 0) {
      this.index++;
      this.zoom = 16;
      this.center = val;
      this.markers = [val];
      this.lng = val[0];
      this.lat = val[1];
    }
  }
  private changePosition(lng: number, lat: number) {
    this.markers = [[lng, lat]];
    this.center = [lng, lat];
    this.lng = lng;
    this.lat = lat;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onSearchResult(pois: any) {
    if (pois.length > 0) {
      const center = pois[0];
      this.center = [center.lng, center.lat];
      this.markers = [[center.lng, center.lat]];
      this.$emit('getLnglat', [center.lng, center.lat]);
      this.changePosition(center.lng, center.lat);
    }
  }
}
