
import {Component, Vue} from 'vue-property-decorator';
import {riderInfos} from '../../../../resources';
import {ObjectId} from 'bson';
import {RiderInfo} from '@/externals/MaxCI-RiderInfo-v1';
import moment from 'moment';

@Component({
  name: 'orderdetail',
})
export default class extends Vue {
  private detailMsg: null | RiderInfo = null;
  private detailId = ObjectId.createFromHexString(
    this.$route.query.id as string,
  );
  created() {
    this.checkMsg();
  }
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  private async checkMsg() {
    try {
      this.detailMsg =
        (
          await riderInfos.find(stage =>
            stage.$match(match =>
              match(
                f => f('_id'),
                e => e.$eq(this.detailId),
              )(
                f => f('spec')('applicationId'),
                e =>
                  e.$eq(
                    ObjectId.createFromHexString(
                      this.$route.params.applicationId,
                    ),
                  ),
              ),
            ),
          )
        ).find(() => true) ?? null;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //转换时间
  getTime(time: string | Date) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //查看大图
  private checkimg(url: string) {
    this.$alert(
      '<img style="max-width:100%;max-height:700px;" src="' + url + '">',
      '图片',
      {
        dangerouslyUseHTMLString: true,
        showConfirmButton: false,
        callback: action => {
          console.log(action);
        },
      },
    );
  }
  //返回
  private bactBtn() {
    this.$router.push({
      query: {menu: this.$route.query.menu, type: 'list'},
    });
  }
}
