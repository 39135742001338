
import {Component, Vue} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {deliveryShops, deliveryShopLabels} from '../../../../../resources';
import moment from 'moment';
import {ObjectId} from 'bson';
import {DeliveryShop} from '@/externals/MaxCI-DeliveryShop-v1';
import {DeliveryShopLabel} from '@/externals/MaxCI-DeliveryShopLabel-v1';

interface ShopValue extends DeliveryShopLabel {
  values: Array<{
    name: string;
    checked: boolean;
  }>;
}
@Component({
  name: 'storeList',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  private dialogSearchLabel = false;
  private dialogLabel = false; //选择标签弹窗
  private activeLabel = [0];
  private labelData: Array<ShopValue> = []; //设置标签数据
  private searchLabelData: Array<ShopValue> = []; //搜索标签数据
  private baseFee = 0;
  private toHomeFee = 0;
  private operateId: ObjectId | null = null;
  private dialogFee = false; //删除弹窗
  private searchData = {
    name: '',
    id: '',
    phone: '',
    label: [] as Array<string>,
  };
  private total = 0;
  private list: Array<DeliveryShop> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private applicationId = this.$route.params.applicationId;

  async created() {
    //回显搜索条件
    if (localStorage.getItem('searchData')) {
      this.searchData = JSON.parse(localStorage.getItem('searchData') ?? '');
      localStorage.setItem('searchData', '');
    }
    //回显分页
    if (localStorage.getItem('listParams')) {
      this.listParams = JSON.parse(localStorage.getItem('listParams') ?? '');
      localStorage.setItem('listParams', '');
    }
    this.updateList();
    //查询标签数据
    const list = (await deliveryShopLabels.find(stage =>
      stage.$match(match =>
        match(
          f => f('spec')('applicationId'),
          e => e.$eq(ObjectId.createFromHexString(this.applicationId)),
        ),
      ),
    )) as Array<ShopValue>;
    list.forEach(item => {
      item.values = [];
      if (item.values) {
        item.spec.values.forEach(valueItem => {
          item.values.push({
            name: valueItem,
            checked: false,
          });
        });
      }
    });
    this.labelData = list;
    this.searchLabelData = list;
  }
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  //重置搜索标签
  private resetLabel() {
    this.searchLabelData.forEach(item => {
      item.values.forEach(valItem => {
        valItem.checked = false;
      });
    });
    this.searchData.label = [];
    this.checkList();
  }
  //搜索选中标签
  private searchSelectLabel() {
    const labels = [] as Array<string>;
    this.searchLabelData.forEach(item => {
      item.values.forEach(valItem => {
        if (valItem.checked) {
          labels.push(item.spec.name + ':' + valItem.name);
        }
      });
    });
    this.searchData.label = labels;
    this.checkList();
  }
  //选择标签保存
  private labelSumbit() {
    const labels = [] as Array<string>;
    this.labelData.forEach(item => {
      item.values.forEach(valueItem => {
        if (valueItem.checked) {
          labels.push(item.spec.name + ':' + valueItem.name);
        }
      });
    });
    deliveryShops
      .update(
        filter =>
          filter(
            f => f('_id'),
            e => e.$eq(this.operateId as ObjectId),
          )(
            f => f('spec')('applicationId'),
            e =>
              e.$eq(
                ObjectId.createFromHexString(this.$route.params.applicationId),
              ),
          ),
        update =>
          update.$set(s => {
            s(f => f('spec')('labels'), labels);
            return s;
          }),
      )
      .then(res => {
        if (res.length > 0) {
          this.dialogLabel = false;
          this.operateId = null;
          this.$message.success('修改成功');
          this.updateList();
        }
      });
  }
  //选择标签
  private changeLabel(index: number, indexValue: number) {
    if (!this.labelData[index].spec.multiSelect) {
      this.labelData[index].values.forEach((item, labelIndex) => {
        if (labelIndex !== indexValue) {
          item.checked = false;
        }
      });
    }
    this.labelData[index].values[indexValue].checked =
      !this.labelData[index].values[indexValue].checked;
  }
  //选择标签
  private selectLabel(data: DeliveryShop) {
    this.operateId = data._id;
    this.dialogLabel = true;
    //标签回显
    data.spec.labels.forEach(item => {
      const itemData = item.split(':');
      this.labelData.forEach(labelItem => {
        if (itemData[0] === labelItem.spec.name) {
          labelItem.values.forEach(valueItem => {
            if (itemData[1] === valueItem.name) {
              valueItem.checked = true;
            }
          });
        }
      });
    });
  }
  //新增
  private addBtn() {
    this.$router.push({
      query: {menu: this.$route.query.menu, type: 'add'},
    });
  }
  //编辑
  private editBtn(id: ObjectId) {
    localStorage.setItem('searchData', JSON.stringify(this.searchData));
    localStorage.setItem('listParams', JSON.stringify(this.listParams));
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'edit',
        id: id.toHexString(),
      },
    });
  }
  //处理标签数据
  private getLabel(label: string, type: string) {
    let value = '';
    if (type === 'name') {
      value = label.split(':')[0];
    } else {
      value = label.split(':')[1];
    }
    return value;
  }
  //转换时间
  getTime(time: string) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //获取图片
  private getImage(img: string) {
    return this.downloadUrl + img;
  }
  //搜索数据
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  private async updateList() {
    this.listLoading = true;
    try {
      const list = await deliveryShops.find(stage =>
        stage
          .$match(match => {
            if (this.searchData.name) {
              match(
                f => f('spec')('name'),
                e => e.$regex(new RegExp(this.searchData.name)),
              );
            }
            if (this.searchData.label.length > 0) {
              match(
                f => f('spec')('labels'),
                e => e.$in(this.searchData.label),
              );
            }
            if (this.searchData.id) {
              match(
                f => f('_id'),
                e => e.$eq(ObjectId.createFromHexString(this.searchData.id)),
              );
            }
            if (this.searchData.phone) {
              match(
                f => f('spec')('phone'),
                e => e.$regex(new RegExp(this.searchData.phone)),
              );
            }
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(ObjectId.createFromHexString(this.applicationId)),
            );
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('creationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
  //修改配送费提示
  private feePopUps(data: DeliveryShop) {
    this.operateId = data._id;
    this.baseFee = data.spec.shopFee.baseFee / 100;
    this.toHomeFee = data.spec.shopFee.toHomeFee / 100;
    this.dialogFee = true;
  }
  //修改配送费保存
  private async feeSumbit() {
    try {
      if (this.operateId) {
        if (this.baseFee === null || this.toHomeFee === null) {
          this.$message.error('费用不能为空');
        } else {
          deliveryShops
            .update(
              filter =>
                filter(
                  f => f('_id'),
                  e => e.$eq(this.operateId as ObjectId),
                )(
                  f => f('spec')('applicationId'),
                  e =>
                    e.$eq(
                      ObjectId.createFromHexString(
                        this.$route.params.applicationId,
                      ),
                    ),
                ),
              update =>
                update.$set(s => {
                  s(f => f('spec')('shopFee'), {
                    baseFee: Math.round(Number(this.baseFee) * 100),
                    toHomeFee: Math.round(Number(this.toHomeFee) * 100),
                  });
                  return s;
                }),
            )
            .then(res => {
              if (res.length > 0) {
                this.dialogFee = false;
                this.operateId = null;
                this.$message.success('已删除');
                this.updateList();
              }
            });
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
}
