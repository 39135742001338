
import {Component, Vue} from 'vue-property-decorator';
import multiSelect from '@/components/multiSelect/index.vue';
import {applications, applicationRequests} from '../../../../resources';
import {ObjectId} from 'bson';
import {DeliveryConfig} from '@/externals/MaxCI-Application-v1';
import {Form} from 'element-ui';
import {ApplicationServiceConfig} from '@/externals/MaxCI-Application-v1';
@Component({
  name: 'deliveryFeeSetting',
  components: {
    multiSelect,
  },
})
export default class extends Vue {
  private validprice = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (!/(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(value)) {
      callback(new Error('价格格式有误'));
    } else {
      callback();
    }
  };
  private serviceConfigs: null | ApplicationServiceConfig = null;
  private formData = {
    shopBaseFee: 0, //店铺基础费用
    shopToHomeFee: 0, //店铺送货上门费用
    riderBaseFee: 0 as null | number, //骑手基础费用
    riderToHomeFee: 0 as null | number, //骑手送货上门费用
  };
  private rules = {
    shopBaseFee: [
      {required: true, message: '请输入店铺基础费用', trigger: 'blur'},
      {validator: this.validprice, trigger: 'blur'},
    ],
    shopToHomeFee: [
      {required: true, message: '请输入送货上门费用', trigger: 'blur'},
      {validator: this.validprice, trigger: 'blur'},
    ],
    riderBaseFee: [
      {required: true, message: '请输入店铺基础费用', trigger: 'blur'},
      {validator: this.validprice, trigger: 'blur'},
    ],
    riderToHomeFee: [
      {required: true, message: '请输入送货上门费用', trigger: 'blur'},
      {validator: this.validprice, trigger: 'blur'},
    ],
  };
  async created() {
    try {
      const application = (
        await applications.find(stage =>
          stage.$match(match => {
            match(
              f => f('_id'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            );
            return match;
          }),
        )
      ).find(() => true);
      this.serviceConfigs = application?.spec.serviceConfigs ?? null;
      if (
        application?.spec.serviceConfigs?.配送?.shopFee.baseFee === undefined
      ) {
        this.formData.shopBaseFee = 0;
      } else {
        this.formData.shopBaseFee =
          application?.spec.serviceConfigs.配送?.shopFee.baseFee / 100;
      }
      if (
        application?.spec.serviceConfigs?.配送?.shopFee.toHomeFee === undefined
      ) {
        this.formData.shopToHomeFee = 0;
      } else {
        this.formData.shopToHomeFee =
          application?.spec.serviceConfigs.配送?.shopFee.toHomeFee / 100;
      }
      if (
        application?.spec.serviceConfigs?.配送?.riderFee.baseFee === undefined
      ) {
        this.formData.riderBaseFee = null;
      } else {
        this.formData.riderBaseFee =
          application?.spec.serviceConfigs.配送?.riderFee.baseFee / 100;
      }
      if (
        application?.spec.serviceConfigs?.配送?.riderFee.toHomeFee === undefined
      ) {
        this.formData.riderToHomeFee = null;
      } else {
        this.formData.riderToHomeFee =
          application?.spec.serviceConfigs.配送?.riderFee.toHomeFee / 100;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  private submitForm() {
    (this.$refs.formData as Form).validate(valid => {
      if (valid) {
        try {
          const fee = {
            shopFee: {
              baseFee: Math.round(
                Number(
                  this.formData.shopBaseFee ? this.formData.shopBaseFee : 0,
                ) * 100,
              ),
              toHomeFee: Math.round(
                Number(
                  this.formData.shopToHomeFee
                    ? this.formData.shopToHomeFee
                    : '',
                ) * 100,
              ),
            },
            riderFee: {
              baseFee: Math.round(
                Number(
                  this.formData.riderBaseFee ? this.formData.riderBaseFee : 0,
                ) * 100,
              ),
              toHomeFee: Math.round(
                Number(
                  this.formData.riderToHomeFee
                    ? this.formData.riderToHomeFee
                    : 0,
                ) * 100,
              ),
            },
          } as DeliveryConfig;
          let serviceConfigs = null as null | ApplicationServiceConfig;
          if (this.serviceConfigs) {
            if (this.serviceConfigs.配送) {
              serviceConfigs = this.serviceConfigs;
              serviceConfigs.配送 = fee;
            } else {
              serviceConfigs = {
                ...this.serviceConfigs,
                配送: fee,
              };
            }
          } else {
            serviceConfigs = {
              配送: fee,
            };
          }
          applicationRequests
            .create(
              [
                {
                  spec: {
                    type: '更新应用',
                    applicationId: ObjectId.createFromHexString(
                      this.$route.params.applicationId,
                    ),
                    serviceConfigs: serviceConfigs,
                  },
                },
              ],
              {
                watch: {
                  filter: filter =>
                    filter(
                      f => f('operationType'),
                      e => e.$eq('update'),
                    )(
                      f => f('fullDocument')('status')('phase'),
                      e => e.$exists(true),
                    ),
                },
                fullResource: true,
              },
            )
            .then(res => {
              if (res[0].status?.phase === '成功') {
                this.$message.success('保存成功');
              } else {
                this.$message.error(res[0].status?.conditions[0].message ?? '');
              }
            });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.$message.error('网络异常，请稍后重试');
        }
      } else {
        return false;
      }
    });
  }
}
