
import {Component, Vue} from 'vue-property-decorator';
import {
  groupDeliveryPointLabels,
  groupDeliveryPoints,
} from '../../../../../resources';
import {Form} from 'element-ui';
import {ObjectId} from 'bson';
import {ShopLabel} from '@/externals/MaxCI-ShopLabel-v1';
import {GeometrySchema} from '@/externals/MaxCI-Shop-v1';
import {AxiosError} from 'axios';
import scopeMap from '@/components/scopeMap/index.vue';

interface ShopValue extends ShopLabel {
  values: Array<{
    name: string;
    checked: boolean;
  }>;
}
@Component({
  name: 'deliveryPointEdit',
  components: {
    scopeMap,
  },
})
export default class extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private validName = (rule: any, value: string, callback: any) => {
    if (value.length > 32 || value.length < 2) {
      callback(new Error('名称必须长度必须小于32个字符且大于2个字符'));
    } else {
      callback();
    }
  };
  private validprice = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (!/(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(value)) {
      callback(new Error('价格格式有误'));
    } else {
      callback();
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private validPhone = (rule: any, value: string, callback: any) => {
    if (!/(^1[23456789]\d{9}$)/.test(value)) {
      callback(new Error('手机号格式不正确'));
    } else {
      callback();
    }
  };
  private detailId = ObjectId.createFromHexString(
    this.$route.query.id as string,
  );
  private dialogLabel = false; //选择标签弹窗
  private activeLabel = [0];
  private labelData: Array<ShopValue> = []; //标签数据
  private submitFlag = false; //提交开关
  private formData = {
    name: '',
    address: '',
    contactName: '',
    phone: '',
    geometry: {
      type: 'Point',
      coordinates: [0, 0] as [number, number],
    } as GeometrySchema,
    description: '',
    labels: [] as Array<string>,
    applicationId: ObjectId.createFromHexString(
      this.$route.params.applicationId,
    ) as ObjectId,
    serviceScope: [] as [number, number][][],
  };
  private rules = {
    name: [
      {required: true, message: '请输入点位名称', trigger: 'blur'},
      {validator: this.validName, trigger: 'blur'},
    ],
    address: [{required: true, message: '请输入点位地址', trigger: 'blur'}],
    phone: [
      {required: true, message: '请输入联系电话', trigger: 'blur'},
      {validator: this.validPhone, trigger: 'blur'},
    ],
    contactName: [{required: true, message: '请输入联系人', trigger: 'blur'}],
  };

  async created() {
    try {
      //查询标签数据
      const list = (await groupDeliveryPointLabels.find(stage =>
        stage.$match(match =>
          match(
            f => f('spec')('applicationId'),
            e => e.$eq(this.formData.applicationId),
          ),
        ),
      )) as Array<ShopValue>;
      list.forEach(item => {
        item.values = [];
        if (item.values) {
          item.spec.values.forEach(valueItem => {
            item.values.push({
              name: valueItem,
              checked: false,
            });
          });
        }
      });
      this.labelData = list;
      //获取店铺详情
      const detailMsg = (
        await groupDeliveryPoints.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$eq(this.detailId),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            ),
          ),
        )
      ).find(() => true)?.spec;
      if (detailMsg) {
        this.formData.name = detailMsg.name;
        this.formData.address = detailMsg.address;
        this.formData.contactName = detailMsg.contactName;
        this.formData.phone = detailMsg.phone;
        this.formData.description = detailMsg.description ?? '';
        this.formData.geometry = detailMsg.geometry;
        if (detailMsg.serviceScope.type === 'Polygon') {
          this.formData.serviceScope = detailMsg.serviceScope.coordinates;
        }
        //标签
        this.formData.labels = detailMsg.labels;
        this.formData.labels.forEach(item => {
          const itemData = item.split(':');
          this.labelData.forEach(labelItem => {
            if (itemData[0] === labelItem.spec.name) {
              labelItem.values.forEach(valueItem => {
                if (itemData[1] === valueItem.name) {
                  valueItem.checked = true;
                }
              });
            }
          });
        });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //处理标签数据
  private getLabel(label: string, type: string) {
    let value = '';
    if (type === 'name') {
      value = label.split(':')[0];
    } else {
      value = label.split(':')[1];
    }
    return value;
  }
  //选择标签保存
  private labelSumbit() {
    const labels = [] as Array<string>;
    this.labelData.forEach(item => {
      item.values.forEach(valueItem => {
        if (valueItem.checked) {
          labels.push(item.spec.name + ':' + valueItem.name);
        }
      });
    });
    this.formData.labels = labels;
    this.dialogLabel = false;
  }
  //选择标签
  private changeLabel(index: number, indexValue: number) {
    if (!this.labelData[index].spec.multiSelect) {
      this.labelData[index].values.forEach((item, labelIndex) => {
        if (labelIndex !== indexValue) {
          item.checked = false;
        }
      });
    }
    this.labelData[index].values[indexValue].checked =
      !this.labelData[index].values[indexValue].checked;
  }
  //获取地图坐标范围数组
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getScopePosi(arr: any) {
    if (arr.length !== 0) {
      const serviceScope = [] as [number, number][];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      arr.forEach((item: any) => {
        if (item.lng) {
          serviceScope.push([item.lng, item.lat]);
        } else {
          serviceScope.push(item);
        }
      });
      serviceScope.push(serviceScope[0]);
      this.formData.serviceScope = [serviceScope];
      //this.formData.serviceScope.push(serviceScope);
      // const result = arr.every(
      //   item => JSON.stringify(item) === JSON.stringify(arr[0]),
      // );
      // if (!result) {
      //   this.formData.serviceScope = [];
      //   arr.push(arr[0]);
      //   this.formData.serviceScope.push(arr);
      // }
    }
  }
  //获取地图数据
  private getPosi(arr: [number, number]) {
    this.formData.geometry.coordinates = arr;
  }
  //修改
  private submitForm() {
    (this.$refs.formData as Form).validate(async valid => {
      if (valid) {
        if (this.formData.geometry.coordinates[0] === 0) {
          this.$message.error('请选择店铺位置');
          return false;
        }
        if (this.formData.serviceScope.length === 0) {
          this.$message.error('请选择范围');
          return false;
        }
        try {
          this.submitFlag = true;
          const groupDeliveryPoint = await groupDeliveryPoints.update(
            filter =>
              filter(
                f => f('_id'),
                e => e.$eq(this.detailId),
              )(
                f => f('spec')('applicationId'),
                e =>
                  e.$eq(
                    ObjectId.createFromHexString(
                      this.$route.params.applicationId,
                    ),
                  ),
              ),
            update => {
              update.$set(s => {
                s(f => f('spec')('serviceScope'), {
                  type: 'Polygon',
                  coordinates: this.formData.serviceScope,
                })(f => f('spec')('name'), this.formData.name)(
                  f => f('spec')('address'),
                  this.formData.address,
                )(f => f('spec')('contactName'), this.formData.contactName)(
                  f => f('spec')('phone'),
                  this.formData.phone,
                )(f => f('spec')('description'), this.formData.description)(
                  f => f('spec')('labels'),
                  this.formData.labels,
                )(f => f('spec')('geometry'), this.formData.geometry);
                return s;
              });
              return update;
            },
          );
          if (groupDeliveryPoint.length > 0) {
            this.$message.success('保存成功');
            this.backBtn();
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (e.isAxiosError) {
            const axiosError = e as AxiosError;
            if (
              axiosError.response?.status === 500 &&
              (axiosError.response.data.name === 'MongoError' ||
                axiosError.response.data.name === 'Error') &&
              axiosError.response.data.message.indexOf('E11000') > -1
            ) {
              this.$message.error('名称不能重复');
            }
          }
        } finally {
          this.submitFlag = false;
        }
      } else {
        return false;
      }
    });
  }
  //重置
  private resetForm() {
    this.formData.geometry.coordinates = [0, 0];
    (this.$refs.formData as Form).resetFields();
  }
  //返回
  private backBtn() {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'list',
      },
    });
  }
}
