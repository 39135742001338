
import {Component, Vue} from 'vue-property-decorator';
import Submenu from '@/components/Submenu/index.vue';
import deliveryApplication from './components/application/index.vue';
import storeLabel from './components/store/storeLabel/index.vue';
import storeIndex from './components/store/storeList/index.vue';
import pointSetting from './components/point/index.vue';
import deliveryFeeSetting from './components/deliveryFee/index.vue';
import deliveryIndex from './components/deliveryList/index.vue';
import sorterIndex from './components/sorterList/index.vue';
import deliveryPointLabel from './components/deliveryPoint/deliveryPointLabel/index.vue';
import deliveryPointList from './components/deliveryPoint/deliveryPointList/index.vue';

@Component({
  name: 'delivery',
  components: {
    Submenu,
    deliveryApplication,
    storeLabel,
    storeIndex,
    pointSetting,
    deliveryFeeSetting,
    deliveryIndex,
    sorterIndex,
    deliveryPointLabel,
    deliveryPointList,
  },
})
export default class extends Vue {
  private menu = '';
  private submenu = [
    {
      top: '配送员',
      list: [
        {name: '骑手列表', active: true},
        {name: '分拣员列表', active: false},
      ],
    },
    {
      top: '',
      list: [{name: '申请列表', active: false}],
    },
    {
      top: '配送门店',
      list: [
        {name: '门店列表', active: false},
        {name: '门店标签', active: false},
      ],
    },
    {
      top: '配送点位',
      list: [
        {name: '点位列表', active: false},
        {name: '点位标签', active: false},
      ],
    },
    {
      top: '',
      list: [{name: '点位配置', active: false}],
    },
    {
      top: '',
      list: [{name: '配送费设置', active: false}],
    },
  ];
  created() {
    this.$store.state.applicationId = this.$route.params.applicationId;
    this.menu = this.$route.query.menu
      ? (this.$route.query.menu as string)
      : '骑手列表';
  }
}
