
import {Component, Vue} from 'vue-property-decorator';
import storeList from './list.vue';
import storeAdd from './add.vue';
import storeEdit from './edit.vue';
@Component({
  name: 'storeIndex',
  components: {
    storeList,
    storeAdd,
    storeEdit,
  },
})
export default class extends Vue {
  private type = '';
  created() {
    this.type = this.$route.query.type
      ? (this.$route.query.type as string)
      : 'list';
  }
}
