
import {Component, Vue, Watch} from 'vue-property-decorator';
import multiSelect from '@/components/multiSelect/index.vue';
import Pagination from '@/components/Pagination/index.vue';
import {
  riderInfos,
  deliveryShops,
  deliveryShopLabels,
  deliveryShopRequests,
  roleDeleteRequests,
} from '../../../../resources';
import {ObjectId} from 'bson';
import {RiderInfo} from '@/externals/MaxCI-RiderInfo-v1';
import {DeliveryShopLabel} from '@/externals/MaxCI-DeliveryShopLabel-v1';
import {DeliveryShop} from '@/externals/MaxCI-DeliveryShop-v1';

interface DeliveryShopLabelValue extends DeliveryShopLabel {
  values: Array<{
    name: string;
    checked: boolean;
  }>;
}
@Component({
  name: 'sorterList',
  components: {
    multiSelect,
    Pagination,
  },
})
export default class extends Vue {
  private shopIds = [] as Array<string>; //门店id
  private selectShopLabels: Array<string> = []; //选择的店铺标签
  private shopList: Array<DeliveryShop> = [];
  private allShopCheck = false; //门店是否全选
  private selectedShopShow = false; //查看预选中门店
  private labelData: Array<DeliveryShopLabelValue> = []; //搜索标签数据
  private dialogSelectShop = false;
  private reason = '';
  private saveLoading = false;
  private dialogDel = false;
  private operateId: null | ObjectId = null;
  private operateData: null | RiderInfo = null;
  private dialogReview = false; // 操作弹窗
  private title = '';
  private total = 0;
  private list: Array<RiderInfo> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private searchData = {name: '', phone: '', working: '' as string | boolean};
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  async created() {
    this.updateList();
  }
  @Watch('dialogSelectShop')
  private changeDialogSelectShop() {
    if (!this.dialogSelectShop) {
      this.allShopCheck = false;
      this.selectedShopShow = false;
    }
  }
  @Watch('shopIds', {deep: true})
  private changeShopIds() {
    if (this.shopList.length === this.shopIds.length) {
      this.allShopCheck = true;
    } else {
      this.allShopCheck = false;
    }
  }
  //删除提示
  private delPopUps(row: RiderInfo) {
    this.operateId = row._id;
    this.operateData = row;
    this.dialogDel = true;
  }
  //删除
  private async delSumbit() {
    try {
      this.saveLoading = true;
      const riderInfo = await riderInfos.delete(filter =>
        filter(
          f => f('_id'),
          e => e.$eq(this.operateId as ObjectId),
        )(
          f => f('spec')('applicationId'),
          e =>
            e.$eq(
              ObjectId.createFromHexString(this.$route.params.applicationId),
            ),
        ),
      );
      if (riderInfo) {
        const roleDeleteRequest = await roleDeleteRequests.create(
          [
            {
              spec: {
                type: '配送员分拣',
                userId: (this.operateData as RiderInfo).spec.userId,
                applicationId: ObjectId.createFromHexString(
                  this.$route.params.applicationId,
                ),
              },
            },
            {
              spec: {
                type: '配送员应用',
                userId: (this.operateData as RiderInfo).spec.userId,
                applicationId: ObjectId.createFromHexString(
                  this.$route.params.applicationId,
                ),
              },
            },
          ],
          {
            watch: {
              filter: filter =>
                filter(
                  f => f('operationType'),
                  e => e.$eq('update'),
                )(
                  f => f('fullDocument')('status')('phase'),
                  e => e.$exists(true),
                ),
            },
            fullResource: true,
          },
        );
        if (roleDeleteRequest) {
          this.dialogDel = false;
          this.operateId = null;
          this.$message.success('已删除');
          this.updateList();
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.saveLoading = false;
    }
  }
  //查看已选中门店
  private async checkSelectedShop() {
    this.selectShopLabels = [];
    this.labelData.forEach(item => {
      item.values.forEach(valItem => {
        valItem.checked = false;
      });
    });
    if (this.selectedShopShow) {
      const shopIds = this.shopIds.map(v => ObjectId.createFromHexString(v));
      try {
        const list = await deliveryShops.find(stage =>
          stage
            .$match(match => {
              match(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              )(
                f => f('_id'),
                e => e.$in(shopIds),
              );
              return match;
            })
            .$facet(facet => facet('table', tableStage => tableStage)),
        );
        this.shopList = list[0].table;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        console.log(e);
        this.$message.error('网络异常，请稍后重试');
      }
    } else {
      this.checkShop();
    }
  }
  //查询门店
  private async checkShop() {
    try {
      const list = await deliveryShops.find(stage =>
        stage
          .$match(match => {
            if (this.selectShopLabels.length > 0) {
              match(
                f => f('spec')('labels'),
                e => e.$in(this.selectShopLabels),
              );
            }
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            );
            return match;
          })
          .$facet(facet => facet('table', tableStage => tableStage)),
      );
      this.shopList = list[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //选择店铺标签
  private async selectLabel() {
    const labels = [] as Array<string>;
    this.labelData.forEach(item => {
      item.values.forEach(valItem => {
        if (valItem.checked) {
          labels.push(item.spec.name + ':' + valItem.name);
        }
      });
    });
    this.selectShopLabels = labels;
    await this.checkShop();
    //判断全选按钮是否选中
    const shopList = [] as Array<string>;
    this.shopList.forEach(item => {
      this.shopIds.forEach(shopItem => {
        if (item._id.equals(ObjectId.createFromHexString(shopItem))) {
          shopList.push(shopItem);
        }
      });
    });
    if (shopList.length === this.shopList.length) {
      this.allShopCheck = true;
    } else {
      this.allShopCheck = false;
    }
  }
  //全选
  private selectAllShop() {
    if (this.allShopCheck) {
      this.shopList.forEach(item => {
        if (this.shopIds.indexOf(item._id.toHexString()) === -1) {
          this.shopIds.push(item._id.toHexString());
        }
      });
    } else {
      const shopList = this.shopList.map(v => v._id.toHexString());
      const shopIds = shopList.filter(items => {
        if (!this.shopIds.includes(items)) return items;
      });
      this.shopIds = shopIds;
    }
  }
  //查询门店标签
  private async checkShopLabel() {
    //查询标签数据
    const list = (await deliveryShopLabels.find(stage =>
      stage.$match(match =>
        match(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        ),
      ),
    )) as Array<DeliveryShopLabelValue>;
    list.forEach(item => {
      item.values = [];
      if (item.values) {
        item.spec.values.forEach(valueItem => {
          item.values.push({
            name: valueItem,
            checked: false,
          });
        });
      }
    });
    this.labelData = list;
  }
  //分配门店保存
  private submitSelectStore() {
    deliveryShopRequests
      .create(
        [
          {
            spec: {
              type: '分配分拣员',
              sorterId: this.operateId as ObjectId,
              shopIds: this.shopIds.map(v => ObjectId.createFromHexString(v)),
              applicationId: this.applicationId,
            },
          },
        ],
        {
          watch: {
            filter: filter =>
              filter(
                f => f('operationType'),
                e => e.$eq('update'),
              )(
                f => f('fullDocument')('status')('phase'),
                e => e.$exists(true),
              ),
          },
          fullResource: true,
        },
      )
      .then(res => {
        if (res) {
          if (res[0].status?.conditions[0].status) {
            this.$message.success('分配成功');
            this.dialogSelectShop = false;
          } else {
            this.$message.error(res[0].status?.conditions[0].message ?? '');
          }
        }
      });
  }
  //分配门店弹窗
  private async selectPopUps(userId: ObjectId) {
    this.operateId = userId;
    await this.checkShopLabel();
    await this.checkShop();
    const list = await deliveryShops.find(stage =>
      stage.$match(match => {
        match(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        )(
          f => f('spec')('sorterId'),
          e => e.$eq(userId),
        );
        return match;
      }),
    );
    this.shopIds = list.map(v => v._id.toHexString());
    this.dialogSelectShop = true;
  }
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  //查看详情
  private toDetail(id: ObjectId) {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'detail',
        id: id.toHexString(),
      },
    });
  }
  //搜索数据
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  private async updateList() {
    this.listLoading = true;
    try {
      const list = await riderInfos.find(stage =>
        stage
          .$match(match => {
            if (this.searchData.name) {
              match(
                f => f('spec')('name'),
                e => e.$regex(new RegExp(this.searchData.name)),
              );
            }
            if (this.searchData.phone) {
              match(
                f => f('spec')('phone'),
                e => e.$regex(new RegExp(this.searchData.phone)),
              );
            }
            if (this.searchData.working !== '') {
              match(
                f => f('spec')('working'),
                e => e.$eq(this.searchData.working as boolean),
              );
            }
            match(
              f => f('spec')('type'),
              e => e.$eq('分拣员'),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            );
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('creationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
}
