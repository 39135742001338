
import {Component, Vue} from 'vue-property-decorator';
import multiSelect from '@/components/multiSelect/index.vue';
import Pagination from '@/components/Pagination/index.vue';
import {riderExamineRequests, riderSignupRequests} from '../../../../resources';
import {ObjectId} from 'bson';
import {RiderSignupRequest} from '@/externals/MaxCI-RiderSignupRequest-v1';
import moment from 'moment';
@Component({
  name: 'deliveryApplicationList',
  components: {
    multiSelect,
    Pagination,
  },
})
export default class extends Vue {
  private reason = '';
  private operateId: null | ObjectId = null;
  private dialogReview = false; // 操作弹窗
  private title = '';
  private total = 0;
  private list: Array<RiderSignupRequest> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private searchData = {name: ''};
  async created() {
    this.updateList();
  }
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  //搜索数据
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  //转换时间
  getTime(time: string) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //审核弹窗
  private reviewPopUps(id: ObjectId, title: string) {
    this.title = title;
    this.operateId = id;
    this.dialogReview = true;
  }
  //审核保存
  private reviewSumbit() {
    riderExamineRequests
      .create(
        [
          {
            spec: {
              requestId: this.operateId as ObjectId,
              status: this.title as '通过' | '驳回',
              applicationId: ObjectId.createFromHexString(
                this.$route.params.applicationId,
              ),
              reason: this.reason,
            },
          },
        ],
        {
          watch: {
            filter: filter =>
              filter(
                f => f('operationType'),
                e => e.$eq('update'),
              )(
                f => f('fullDocument')('status')('phase'),
                e => e.$exists(true),
              ),
          },
          fullResource: true,
        },
      )
      .then(res => {
        if (res.length > 0) {
          this.$message.success('操作成功');
          this.updateList();
          this.dialogReview = false;
        }
      });
  }
  //查看大图
  private checkimg(url: string) {
    this.$alert(
      '<img style="max-width:100%;max-height:700px;" src="' + url + '">',
      '图片',
      {
        dangerouslyUseHTMLString: true,
        showConfirmButton: false,
        callback: action => {
          console.log(action);
        },
      },
    );
  }
  private async updateList() {
    this.listLoading = true;
    try {
      const list = await riderSignupRequests.find(stage =>
        stage
          .$match(match => {
            if (this.searchData.name) {
              match(
                f => f('spec')('name'),
                e => e.$regex(new RegExp(this.searchData.name)),
              );
            }
            match(
              f => f('status'),
              e => e.$exists(false),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            );
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('creationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
}
